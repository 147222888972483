import React from "react";
import AlBahie from "../Components/3D/AlBahie";
import { Helmet } from "react-helmet";

export default function Artists() {
  return (
    <div>
      <Helmet>
        <title>V-art Gallery | Al Bahie MOCO</title>
      </Helmet>
      <AlBahie />
    </div>
  );
}
